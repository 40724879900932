<template>
    <div>
        <layout-header title=" " btn-left="close"></layout-header>

        <div class="container">
            <div class="content">
                <div class="img-wrap">
                    <img src="/assets/images/member/img_email_join.png" alt="">
                </div>
                <div class="join-form">
                    <p class="sub-tit">회원가입이 완료되었습니다. <br>가입하신 이메일로 인증메일이 발송됩니다. <br><strong>이메일 인증</strong> 후 최종가입승인 됩니다.</p>
                    <p class="sub-tit"><br><strong>가입인증메일을 받지 못하셨다면</strong> <br>가입확인메일 재발송 버튼을 통해 다시 받으실 수 있습니다.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LayoutHeader from "@/pages/layouts/layoutHeader";

export default {
    name: "emailJoinDone",
    components: {LayoutHeader},
    data () {
        const caidx = this.webUtils.getParameter("caidx")
        return {
            caidx,
            coAccountVO: {}
        }
    },
    created() {
        if ( this.caidx > 0 ) {
            this.getCoAccount();
        } else {
            this.swalUtils.fire("비정상적인 접근", "메인으로 이동합니다.", "error", true, true)
                .then((result) => {
                });
        }
    },
    methods: {
        getCoAccount() {

            this.$eventBus.$emit("startProgress")
            this.axiosCaller.get( this, this.APIs.CO_ACCOUNT + "/", {
                caidx: this.caidx
            }, (res) => {
                this.$eventBus.$emit("doneProgress")
                const result = res.data
                this.coAccountVO = result.coAccountVO;
                // this.doSendAuth()
            })
        },

        doSendAuth () {

            this.$eventBus.$emit("startProgress", 2)
            this.axiosCaller.post( this, this.APIs.CO_ACCOUNT + "/doSendAuth/" + this.caidx, {
                caidx: this.caidx
            }, (res) => {
                const result = res.data
                if ( result.status === "ok" ) {
                    this.swalUtils.gritter("인증메일 발송", "인증메일이 발송되었습니다. <br>메일 링크를 클릭하여 가입을 완료해주세요.", "info")
                }
                this.$eventBus.$emit("doneProgress", 2)
            })
        },

    }

}
</script>

<style scoped>

</style>